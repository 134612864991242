




















import SubscriptionContractResourceViewModel from '@/src/services/viewModel/resource/SubscriptionContractResourceViewModel';
import Vue from 'vue';

export default Vue.extend({
  hasBackButton: true,
  auth: true,
  data: () => ({
    subscriptionData: new SubscriptionContractResourceViewModel(),
  }),
  methods: {
    getSubscription() {
      return this.$service.api.subscriptions.getMyGastroProfessionalSubscriptions();
    },
    gotoTerminateSubscription() {
      this.$router.push('/gs/memberPortal/gastro-professional/subscription/terminate');
    },
    gotoPauseSubscription() {
      this.$router.push('/gs/memberPortal/gastro-professional/subscription/pause');
    },
    gotoInterruptSubscription() {
      this.$router.push('/gs/memberPortal/gastro-professional/subscription/interrupt');
    },
    gotoForwardSubscription() {
      this.$router.push('/gs/memberPortal/gastro-professional/subscription/forward');
    },
  },
});
